import React from 'react'
import IncomingMessageComponent from './IncomingMessageComponent'
import OutcomingMessageComponent from './OutcomingMessageComponent'

function MessageComponent({ type = "incoming", className = "", children }) {
    return (
        <div className={`${className} flex flex-row justify-end w-full py-3`}>
            {type === "incoming" ?
                <IncomingMessageComponent>{children}</IncomingMessageComponent> :
                <OutcomingMessageComponent>{children}</OutcomingMessageComponent>
            }
        </div>
    )
}

export default MessageComponent
import React from 'react';
import useSettingsStore from '../../../store/SettingsStore';
import SidebarSwitchComponent from '../../buttons/SidebarSwitchComponent';
import { motion } from 'framer-motion';
import { Image } from '@nextui-org/react';
import ChatHistoryComponent from '../../chat/ChatHistoryComponent';

function SideBarComponent() {
    const { hideLeftMenu } = useSettingsStore();

    return (
        <motion.div
            initial={{ width: hideLeftMenu ? '0px' : '18%' }} // Initial width when component mounts
            animate={{ width: hideLeftMenu ? '0px' : '18%' }} // Width when showing or hiding
            exit={{ width: '0px' }} // Width for hiding animation
            transition={{
                type: 'spring',
                stiffness: 300,
                damping: 30
            }}
            className="z-[21] flex flex-col w-full h-screen flex-shrink-0 bg-token-sidebar-surface-primary max-md:!w-0 bg-slate-50 dark:bg-zinc-800 overflow-hidden"
            // style={{ display: hideLeftMenu ? 'none' : 'flex' }}
        >
            {/* Header */}
            <div
                className="w-full flex z-40 items-center px-6 sticky top-0 inset-x-0 flex-shrink-0"
                style={{ height: '4rem' }}
            >
                <SidebarSwitchComponent classNames='bg-main' />
            </div>

            {/* Image container */}
            <div className="flex flex-col justify-center my-4 flex-shrink-0">
                <Image
                    className="p-4 rounded-full"
                    isBlurred
                    src="doctor.webp"
                    alt="avatar"
                />
            </div>

            {/* Content container */}
            <div className="flex flex-col flex-1 min-h-0">
                <span className="font-semibold text-lg px-2 mb-2">Dialogs</span>
                <div className="scroll overflow-y-auto px-2 flex-1 scrollable [&::-webkit-scrollbar]:w-3 [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                    <ChatHistoryComponent />
                </div>
            </div>
        </motion.div>
    );
}

export default SideBarComponent;

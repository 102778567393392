import toast from "react-hot-toast";
import { pb } from "./pb";
/**
 * Sends a request to the backend AI endpoint and handles the streaming response.
 *
 * @param {string} aiName - The name of the AI to use for the request.
 * @param {function} setStatusInner - A function to set the status of the request.
 * @param {function} setResultInner - A function to set the result of the request.
 * @param {string} inputValue - The input value to use for the request.
 * @return {Promise<void>} A Promise that resolves when the request is completed.
 */

export const makeRequestAi = async (chatuuid, setStatusInner, setResultInner, inputValue, addResultMessage) => {
    setStatusInner(true);
    const baseURL = process.env.REACT_APP_API_URL;
    const apiKey = pb.authStore.token;

    // Set up headers
    const headers = {
        "Content-Type": "application/json",
        "Accept": "text/event-stream",
    };
    if (apiKey) {
        headers["Authorization"] = `Bearer ${apiKey}`;
    }

    try {
        const response = await fetch(`${baseURL}/goopenai/${chatuuid}`, {
            method: 'POST',
            headers,
            body: JSON.stringify({ input_value: inputValue })
        });

        if (!response.ok) {
            // Read the error message from the backend
            const errorResponse = await response.json();
            const errorMessage = errorResponse.error || "An unknown error occurred";

            // Display specific error toasts based on the error message
            switch (response.status) {
                case 401:
                    toast.error("Unauthorized: Please log in.");
                    console.error("Unauthorized access:", errorMessage);
                    break;
                case 402:
                    toast.error("You've reached your daily limit.\n Please try again tomorrow or upgrade.");
                    console.error("Payment required:", errorMessage);
                    break;
                case 400:
                    toast.error("Bad request: Please check your input.");
                    console.error("Invalid request:", errorMessage);
                    break;
                case 500:
                    toast.error("Server error: Please try again later.");
                    console.error("Internal server error:", errorMessage);
                    break;
                default:
                    toast.error(errorMessage);
                    console.error("Error:", errorMessage);
                    break;
            }
            throw new Error(errorMessage);  // Stop further processing
        }


        // Create a new Response object with the readable stream
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let prev = ""

        while (true) {
            const { done, value } = await reader.read();
            if (done) {
                setResultInner("");
                addResultMessage(prev, 'ai');
                
                break;
            };
            prev = prev + decoder.decode(value);
            // Append each chunk to the responseText state
            setResultInner(prev);

        }


    } catch (error) {
        console.error("Error in makeRequestAi:", error);
        // toast.error("An error occurred while processing your request.");
    } finally {
        setStatusInner(false);
    }
};
import React from 'react'
import SideBarIconComponent from '../../assets/SideBarIconComponent'
import useSettingsStore from '../../store/SettingsStore'

function SidebarSwitchComponent({ className = "", classNames="", style = {} }) {
    const { hideLeftMenu, setHideLeftMenu } = useSettingsStore()
    return (
        <div className={`cursor-pointer ${className}`} style={style} onClick={() => setHideLeftMenu(!hideLeftMenu)}>
            <SideBarIconComponent className={classNames} />
        </div>
    )
}

export default SidebarSwitchComponent
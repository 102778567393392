import "@fontsource/readex-pro/600.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import '../../../../App.css';
import React from 'react'
import { Toaster } from 'react-hot-toast'
import { Outlet } from 'react-router-dom'
import NavBarNavigationComponent from '../NavBarNavigationComponent';
import SideBarComponent from "./SideBarComponent";
// import FooterComponent from '../FooterComponent';
import { motion } from "framer-motion"


const LayoutComponent = () => {
    
    return (
        <>
            <Toaster toastOptions={{ className: 'toast-style' }} />
            <div className="App wrapper flex h-svh relative flex h-full w-full overflow-hidden transition-colors z-0" >
                <SideBarComponent />
                <motion.div className="relative flex h-full max-w-full flex-1 flex-col overflow-hidden">
                    <NavBarNavigationComponent />
                    <div className="content flex-grow">
                        <Outlet />
                    </div>
                    {/* <FooterComponent /> */}
                </motion.div>

            </div>
        </>
    )
}

export default LayoutComponent
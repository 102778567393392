import React from 'react'
import useSettingsStore from '../../store/SettingsStore'
import MoonComponent from '../../assets/MoonIconComponent'
import SunComponent from '../../assets/SunIconComponent'

function ThemeSwitchComponent() {
    const { theme, setTheme } = useSettingsStore()
    return (
        <button onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')} className="flex items-center justify-center relative allign-middle p-3 bg-background rounded-full ">
            {theme === 'light' ? <div ><MoonComponent /></div> : <div><SunComponent /></div>}
        </button>
    )
}

export default ThemeSwitchComponent
import React, { useState } from 'react';
import { pb } from '../../../services/pb';
import useChatStore from '../../../store/ChatStore';

const GetAllChats = () => {
    return (
        <div>
            <h1>GetAllChats</h1>
        </div>
    );
};

function TestPage() {
    const [messages, setMessages] = useState([]);
    const [formattedChats, setFormattedChats] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const { getChatsFromPB } = useChatStore();

    const getRecords = async () => {
        getChatsFromPB();
    }

    // const getRecords = async () => {
    //     setLoading(true); // Start loading
    //     setError(null); // Reset error
    //     try {
    //         const messages_all = await pb.collection('chats').getFullList({
    //             sort: '-created',
    //             expand: 'messages_via_chat',
    //         });
    //         setMessages(messages_all);
    //         formatChats(messages_all);
    //     } catch (err) {
    //         console.error("Failed to fetch records:", err);
    //         setError("Failed to load records. Please try again.");
    //     } finally {
    //         setLoading(false); // Stop loading
    //     }
    // };

    // const formatChats = (messages_all) => {
    //     const chats = {};
    //     console.log({ messages_all })

    //     messages_all.forEach(chat => {
    //         const chatId = chat.chatuuid;
    //         if (!chats[chatId]) {
    //             chats[chatId] = {
    //                 id: chatId,
    //                 messages: []
    //             };
    //         }

    //         if (chat.expand && chat.expand.messages_via_chat) {
    //             console.log({ yes: chat.expand.messages_via_chat })
    //             chat.expand.messages_via_chat.forEach(message => {
    //                 chats[chatId].messages.push({
    //                     id: message.id,
    //                     text: message.message,
    //                     role: message.sender
    //                 });
    //             });
    //         }

    //     });

    //     setFormattedChats({ chats });
    // };

    return (
        <div>
            <button onClick={getRecords}>Get Records</button>
            {loading && <p>Loading records...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <pre>{JSON.stringify(formattedChats, null, 2)}</pre>
            <GetAllChats />
        </div>
    );
}

export default TestPage;

import React from 'react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

function MarkdownComponent({children, className=""}) {
  return (
    <Markdown className={`markdown-body text-primary dark:text-white prose dark:prose-invert ${className}`} remarkPlugins={[remarkGfm]}>{children}</Markdown>
  )
}

export default MarkdownComponent
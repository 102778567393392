import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { v6 as uuidv6 } from 'uuid';
import { pb } from '../services/pb';


const useChatStore = create(
    persist(
        (set, get) => ({
            chats: {},
            activeChatId: null,

            setActveChatId: (chatId) => set({ activeChatId: chatId }),

            message: "",

            setMessage: (message) => set({ message }),

            cleanChats: () => {
                const { chats } = get();
                return Object.fromEntries(
                    Object.entries(chats).filter(
                        ([_, chat]) => chat.messages.length > 0
                    )
                );
            },

            getActiveChatIndex: () => {
                const { chats, activeChatId } = get();
                return Object.keys(chats).find(index => chats[index].id === activeChatId);
            },

            clearChats: () => {
                set({ chats: {}, activeChatId: null, message: "" });
            },

            getChatsFromPB: async () => {
                const tempChat = {};
                const chatsFromPB = await pb.collection('chats').getList(1, 100, {
                    sort: 'created',
                    expand: 'messages_via_chat',
                });

                for (const [index, chat] of chatsFromPB.items.entries()) {

                    const chatId = chat.chatuuid;
                    if (!tempChat[index]) {
                        tempChat[index] = {
                            id: chatId,
                            messages: []
                        };
                    }

                    if (chat.expand && chat.expand.messages_via_chat) {
                        // Expand messages and push to tempChat
                        for (const message of chat.expand.messages_via_chat) {
                            tempChat[index].messages.push({
                                id: message.id,
                                text: message.message,
                                role: message.sender
                            });
                        }
                    }
                    if (index === chatsFromPB.items.length - 1) {
                        set({ activeChatId: chatId });
                    }
                }
                if (chatsFromPB.items.length > 0) {
                    set({ chats: tempChat });
                }

            },


            // Initialize a chat session (use last or create a new one if none exist)
            initializeChat: () => {
                let chatId = get().activeChatId;
                const cleanedChats = get().cleanChats();
                const getActiveChatIndex = get().getActiveChatIndex();
                // Filter out chats without messages
                if (!chatId || Object.keys(cleanedChats).length === 0 || getActiveChatIndex === undefined) {
                    chatId = uuidv6();
                    set(() => ({
                        chats: {
                            ...cleanedChats,
                            [Object.keys(cleanedChats).length]: { id: chatId, messages: [] },
                        },
                        activeChatId: chatId,
                    }));
                } else {
                    set({ activeChatId: chatId });
                }

                return chatId;
            },

            // Start a new chat session
            startNewChat: () => {
                const chatId = uuidv6();
                const cleanedChats = get().cleanChats();
                const newIndex = Object.keys(cleanedChats).length;
                set(() => ({
                    chats: {
                        ...cleanedChats,
                        [newIndex]: { id: chatId, messages: [] },
                    },
                    activeChatId: chatId,
                }));
                return chatId;
            },

            // Add a message to the current chat
            addMessage: (text, role = 'user') => {
                const { activeChatId } = get();
                if (!activeChatId) return;
                const getActiveChatIndex = get().getActiveChatIndex();
                const messageId = uuidv6();
                const newMessage = { id: messageId, text, role };

                set((state) => ({
                    chats: {
                        ...state.chats,
                        [getActiveChatIndex]: {
                            ...state.chats[getActiveChatIndex],
                            messages: [...state.chats[getActiveChatIndex].messages, newMessage],
                        },
                    },
                }));
            },

            // Get messages for the active chat
            getMessages: () => {
                const { activeChatId, chats } = get();
                const getActiveChatIndex = get().getActiveChatIndex();
                return activeChatId && getActiveChatIndex && chats[getActiveChatIndex]
                    ? chats[getActiveChatIndex].messages
                    : [];
            },
        }),
        {
            name: 'chat-storage', // name for localStorage
            storage: createJSONStorage(() => localStorage),
        }
    )
);

export default useChatStore;

import React from 'react'
import DialogComponent from '../../chat/DialogComponent'
import PromptInputComponent from '../../chat/PromptInputComponent'
// import useSettingsStore from '../../../store/SettingsStore'
import ScrollToBottom from 'react-scroll-to-bottom'
import useChatStore from '../../../store/ChatStore'

function ChatPage() {
    // const { width } = useSettingsStore()
    const { getMessages } = useChatStore()
    const messages = getMessages()
    return (
        <div className='relative flex h-full flex-col'>
            {
            messages.length > 0 ?
                <ScrollToBottom
                    mode='bottom'
                    initialScrollBehavior='auto'
                    followButtonClassName='scroll-to-bottom-button'
                    className='w-full h-0 flex-grow overflow-y-auto flex-none flex-col gap-4 lg:flex'
                    scrollViewClassName='p-2 scrollable [&::-webkit-scrollbar]:w-3 [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500'>
                    <DialogComponent className={`mx-auto md:max-w-3xl lg:max-w-[40rem] xl:max-w-[60rem]`} />
                </ScrollToBottom>
                :
                <div className='w-full h-full flex justify-center items-center'>
                    <div className='text-xl font-semibold text-gray-500 dark:text-gray-400'>How can I assist you today?</div>
                </div>
            }


            <div className="mt-auto w-full flex-col px-2">
                <PromptInputComponent className={`mx-auto w-full  md:max-w-3xl lg:max-w-[40rem] xl:max-w-[60rem] rounded-large `} />
            </div>
        </div>
    )
}

export default ChatPage
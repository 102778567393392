import React from 'react'
import { ROUTES } from '../../constants/routes'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import LayoutComponent from './layouts/LayoutComponent'
import ProtectedRouteComponent from './ProtectedRouteComponent'
import SigninPage from './pages/SigninPage'
import ChatPage from './pages/ChatPage'
import TestPage from './pages/TestPage'


const router = createBrowserRouter(
    createRoutesFromElements(
        (
            <>
                <Route path={ROUTES.DASHBOARD} element={<LayoutComponent />}>
                    <Route path='' element={<ProtectedRouteComponent />}>
                        <Route index element={<ChatPage />} />
                        <Route path={ROUTES.TEST_ROUTE} element={<TestPage />} />
                    </Route>
                </Route>
                <Route path={ROUTES.SIGNIN} element={<SigninPage />} />
            </>
        )
    )
)

function RouterComponent() {
    return (
        <RouterProvider router={router} />
    )
}

export default RouterComponent

import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const useSettingsStore = create(
    persist(
        (set, get) => ({
            theme: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
            setTheme: (theme) => {
                if (theme === 'dark') {
                    document.body.classList.add('dark', 'text-foreground', 'bg-background');
                } else {
                    document.body.classList.remove('dark', 'text-foreground', 'bg-background');
                }
                set({ theme });
            },
            // width: 'lg', 'md', 'sm', 'xs'
            width: { px: "1024", letters: "lg" },
            setWidth: (width) => set({ width }),
            hideLeftMenu: false,
            setHideLeftMenu: (hideLeftMenu) => set({ hideLeftMenu }),
        }),
        {
            name: 'settings-storage',
            storage: createJSONStorage(() => localStorage),
            onRehydrateStorage: () => (state) => {
                // Check if the theme is dark when rehydrating
                if (state?.theme === 'dark') {
                    document.body.classList.add('dark', 'text-foreground', 'bg-background');
                }
            },
        }
    )
);

export default useSettingsStore;

import React from 'react'
// import AvatarComponent from '../parts/AvatarComponent'

function OutcomingMessageComponent({ className = "", children }) {
    return (
        <>
            {/* <div className="mr-3">
                <AvatarComponent/>
            </div> */}
            <div className={`relative max-w-[70%] rounded-large px-5 py-2.5 bg-[#f4f4f4] dark:bg-zinc-700 whitespace-pre-line ${className}`}>{children}</div>
        </>
    )
}

export default OutcomingMessageComponent
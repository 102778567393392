import React from 'react'
import LogoIconComponent from '../../assets/LogoIconComponent'
import MarkdownComponent from '../parts/MarkdownComponent'



function IncomingMessageComponent({ className = "", children }) {
    //whitespace-pre-line
    return (
        <>
            <div className="mr-3">
                <div className=' p-1 rounded-full border border-slate-300 dark:bg-zinc-800  dark:border-zinc-600'>
                    <LogoIconComponent width={24} height={24} className={'opacity-100'} />
                </div>

            </div>
            <div className={`w-full pr-3 ${className}`}><MarkdownComponent>{children}</MarkdownComponent></div>
        </>
    )
}

export default IncomingMessageComponent
import React from 'react'
import PlusIconComponents from '../../assets/PlusIconComponents'
import useChatStore from '../../store/ChatStore'

function NewChatButtonComponent({ className = "" }) {
    const { startNewChat } = useChatStore()

    const handleClick = () => {
        startNewChat()
    }
    return (
        <div role="button"  onClick={handleClick} className={`cursor-pointer flex items-center justify-center relative allign-middle p-3 bg-background rounded-full ${className}`}>
            <PlusIconComponents />
            <span className='hidden min-[850px]:inline pl-1'>New chat</span>
        </div>
    )
}

export default NewChatButtonComponent
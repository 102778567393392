import { Avatar } from '@nextui-org/react';
import React, { forwardRef } from 'react';
import { useAuth } from '../../store/AuthStore';
import { pb } from '../../services/pb';

const AvatarComponent = forwardRef((props, ref) => {
    const {user} = useAuth();
    return (
        <Avatar
            ref={ref} // Forward the ref here
            as="button"
            name={user?.name && user?.name !== '' ? user?.name : null}

            src={user?.avatar && user?.avatar !== '' ? pb.files.getUrl(user, user.avatar) : null}
            showFallback
            {...props} // Spread other props to support additional functionality
            className="w-12 h-12"
        />
    );
});

export default AvatarComponent;

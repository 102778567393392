import { Button, Spinner } from '@nextui-org/react'
import React, { useState } from 'react'
import TextHeaderComponent from '../../text/TextHeaderComponent'
import useAuthStore, { useAuth } from '../../../store/AuthStore'
import { Navigate } from 'react-router-dom'
import { ROUTES } from '../../../constants/routes'
import { pb } from '../../../services/pb'



function SigninPage() {
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuthStore()
  const { login } = useAuth()

  const signInWithGoogle = () => {
    setIsLoading(true)
    login('google')

  }

  return (
    <>
      {user && pb.authStore.isValid && <Navigate to={ROUTES.DASHBOARD} replace />}
      <div className='flex flex-col items-center justify-center h-screen'>
        <TextHeaderComponent>Sign in</TextHeaderComponent>
        {
          !isLoading ?
            <Button className='mt-2 ' color='secondary' onClick={signInWithGoogle} variant="ghost" size="lg"
              startContent={<img alt="Google" width={24} src="https://img.icons8.com/color/48/000000/google-logo.png" />}>Continue with Google
            </Button>
            :
            <Button className='mt-2 ' color='secondary' onClick={signInWithGoogle} variant="ghost" size="lg"
              startContent={<Spinner size="sm" />}>Continue with Google
            </Button>
        }
        <div className='m-5 p-5 text-sm text-center text-primary-300 dark:text-gray-500 max-w-[40rem]'>
          Eccex is a medical encyclopedia intended solely for informational and educational purposes.
          The service does not provide medical recommendations and is not a substitute for a doctor’s consultation.
          By continuing to use the service, you confirm that you are at least 21 years old and agree to our Terms of Use and Privacy Policy.
        </div>
      </div>

    </>

  )
}

export default SigninPage
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { pb } from '../../services/pb';
import { useAuth } from '../../store/AuthStore';
import { ROUTES } from '../../constants/routes';


const ProtectedRouteComponent = () => {
    const { user } = useAuth();
    return pb.authStore.isValid && user ? <Outlet /> : <Navigate to={ROUTES.SIGNIN} replace />;
};

export default ProtectedRouteComponent
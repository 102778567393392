import React from 'react'
import MessageComponent from './MessageComponent'
import useChatStore from '../../store/ChatStore'


function DialogComponent({ className }) {
    // outcoming or incoming
    const { getMessages, message } = useChatStore()
    const messages = getMessages();

    return (
        <div className={`${className} w-full flex justify-end`}>
            <div className='flex w-full flex-col justify-end'>
                {messages.map((msg) => (
                    msg.role === 'user' ?
                        <MessageComponent type="outcoming" key={msg.id}>{msg.text}</MessageComponent> :
                        <MessageComponent type="incoming" key={msg.id}>{msg.text}</MessageComponent>
                ))}
                {!!message && <MessageComponent type="incoming" key={"curent_stream"}>{message}</MessageComponent>}
            </div>
        </div>
    )
}

export default DialogComponent
import React, { useRef, useState } from 'react'
import ClipComponent from '../../assets/ClipIconComponent'
import MicrophoneComponent from '../../assets/MicrophoneIconComponent'
import useSettingsStore from '../../store/SettingsStore'
import TextareaAutosize from 'react-textarea-autosize'
import SendMessageComponent from './SendMessageComponent'

function PromptInputComponent({ className = "" }) {
    const [message, setMessage] = useState("")
    const { width } = useSettingsStore()
    // test request
    const textareaRef = useRef(null)
    const sendMessageRef = useRef(null)
    const setFocus = () => {
        if (textareaRef.current) {
            textareaRef.current.focus() // Refocus the textarea
        }
    }

    const handleKeyDown = (e) => {
        // e.altKey && отправка на enter
        if ( e.key === 'Enter') {
            e.preventDefault()
            sendMessageRef.current.onClickHandler() // Call onClickHandler from SendMessageComponent
        }
    }

    return (
        <div className={`mx-auto w-full max-w-[${width.px}px] bg-gray-200 dark:bg-zinc-700 rounded-large ${className}`}>
            <div className="h-auto mb-1 p-3 px-4 flex items-center">
                <div className="mr-2">
                    <button className="opacity-40 group flex justify-center items-center rounded-full outline-none 
                    focus:outline-none 
                    hover:bg-gray-50 dark:hover:bg-gray-700 focus:bg-gray-50 dark:focus:bg-gray-600 py-5 transition-all duration-200 group w-7 h-7" title="open select attachments modal" aria-label="open select attachments modal">
                        <ClipComponent />
                    </button>
                </div>
                <div className="grow md:mr-5 xs:mr-4">
                    <TextareaAutosize
                        autoFocus={true}
                        maxRows={5}
                        minRows={1}
                        className="bg-gray-200 dark:bg-zinc-700 flex max-w-full w-full py-2 content-center outline-none placeholder:text-black dark:placeholder:text-white placeholder:opacity-40 resize-none  [&::-webkit-scrollbar]:w-3
            [&::-webkit-scrollbar-track]:bg-gray-100
            [&::-webkit-scrollbar-thumb]:bg-gray-300
            dark:[&::-webkit-scrollbar-track]:bg-neutral-700
            dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 cursor-auto"
                        cols="30"
                        rows="1"
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        placeholder="Write your message"
                        aria-label="Write your message here" />
                </div>
                <div className="flex">
                    {!message.length > 0 && <button className="group flex justify-center p-5 items-center rounded-full outline-none focus:outline-none transition-all opacity-40 duration-200 group w-7 h-7 hover:bg-gray-50 dark:hover:bg-gray-700 focus:bg-gray-50 dark:focus:bg-gray-600" title="start recording" aria-label="start recording">
                        <MicrophoneComponent />
                    </button>}

                    <SendMessageComponent
                        active={message.length > 0}
                        className="group flex justify-center ml-2 p-5 items-center outline-none rounded-full focus:outline-none  transition-all duration-200 group bg-primary text-white dark:bg-white dark:text-black w-7 h-7 hover:bg-gray-700 dark:hover:bg-gray-100 disabled:bg-gray-400  dark:disabled:bg-neutral-500"
                        ref={sendMessageRef}
                        message={message}
                        setMessage={setMessage}
                        setFocus={setFocus} />

                </div>
            </div>
        </div>
    )
}

export default PromptInputComponent
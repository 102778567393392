import React from 'react'
import useChatStore from '../../store/ChatStore';
import capitalizeFirstLetter from '../../functions/capitalizeFirstLetterFunction';

function ChatHistoryComponent({ onClick: onClickExternal = () => { } }) {
    const { chats, setActveChatId } = useChatStore();
    const onClick = (chatId) => {
        setActveChatId(chatId);
        onClickExternal();
    };

    return (
        <>
            {Object.values(chats).reverse().map((chat) => (
                <div className="cursor-pointer items-center w-full rounded-lg" key={chat.id} onClick={() => onClick(chat.id)}>
                    <div className='group relative w-full  p-2 rounded-lg grow  overflow-hidden whitespace-nowrap bg-slate-50 dark:bg-zinc-800 hover:bg-gray-200 dark:hover:bg-zinc-700'>
                        { chat.messages[0] && chat.messages[0].text ? capitalizeFirstLetter(chat.messages[0].text).slice(0, 70) : 'New chat' }
                        <div className="absolute right-0 top-0 w-10 h-10 bg-gradient-to-l from-slate-50 via-slate-50 dark:from-zinc-800 dark:via-zinc-800 group-hover:from-gray-200 dark:group-hover:from-zinc-700 group-hover:via-gray-200 dark:group-hover:via-zinc-700"></div>
                    </div>
                </div>
            ))}
        </>

    )
}

export default ChatHistoryComponent